export const INT_MIN_X_Y = 0;
export const INT_SCALE_FACTOR = 1;
export const INT_NEXT_PLAYER = -1;
export const INT_ROUND_RANGE = 3;
export const INT_LAST_PLAYED_PLAYER = 0;
export const INT_ASPECT_RATIO = 1440;
export const INT_Y_OFFSET = 40;
export const INT_X_SCALE_MULTIPLIER = 32;
export const FLOAT_Y_PLAYER_POSITION_EXPONENT = 1.5;
export const INT_MAX_PLAYER_LEVEL = 9;
export const INT_MIN_PLAYER_HP = 0;
export const INT_COST_QUOTIENT = 4;
export const gameStates = {
  1: "carousel",
  2: "carousel",
  3: "teleporting",
  4: "planning_prepare",
  5: "planning",
  6: "teleporting",
  7: "battle_preprare",
  8: "battle",
  9: "battle_end",
  10: "round_end",
};
