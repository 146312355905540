import { useEffect, useState } from "react";

import { gameStates } from "@/game-tft/constants-in-game.mjs";
import {
  calculateAvgStars,
  calculateBoardValue,
  getOpponentData,
  getPlayerData,
} from "@/game-tft/in-game-utils.mjs";
import clone from "@/util/clone.mjs";
import deepEqual from "@/util/deep-equal.mjs";
import deepMerge from "@/util/deep-merge.mjs";

const KEYS_TO_UPDATE = ["health", "goldInterest", "goldStreak"];
const GOLD_INCOME_RATE = 2; // Players get 2 gold/round
const BATTLE_STATE = 8;

function generateRounds(next, player, gameData) {
  const name = player.summonerName;
  next.rounds[name] = next.rounds[name] ?? [];
  const round = next.rounds[name].find((i) => i.round === next.round);
  if (round) {
    // Update the round reference, we're still receiving updates for this round
    for (const key of KEYS_TO_UPDATE) round[key] = player[key];
  } else {
    if (
      !gameData.firstCombatFrame ||
      (typeof gameStates[gameData.state] !== "undefined" &&
        gameStates[gameData.state] !== gameStates[BATTLE_STATE])
    )
      return;
    // Create the round and create initial obj
    next.rounds[name].push({
      round: next.round, // Shouldn't be modified after being set
      health: player.health,
      goldInterest: player.goldInterest,
      goldStreak: player.goldStreak,
      isMinion: !gameData.roundOpponent, // Shouldn't be modified after being set
    });
  }
}

function getAggStatsByPlayerRounds(playerRounds) {
  const accumulator = {
    goldStreak: 0,
    goldInterest: 0,
    roundsWon: 0,
    roundsLost: 0,
  };
  if (!Array.isArray(playerRounds)) return accumulator;
  const length = playerRounds.length;
  return playerRounds.reduce((acc, curRound, idx, arr) => {
    if (
      idx !== 0 && // Don't count the first round
      !curRound.isMinion && // Don't count minion rounds
      length - 1 !== idx // Don't count the latest round (its still in progress)
    ) {
      const prevRound = arr[idx - 1];
      if (curRound.health < prevRound.health) {
        acc.roundsLost += 1;
      } else {
        acc.roundsWon += 1;
      }
    }
    acc.goldStreak += curRound.goldStreak;
    acc.goldInterest += curRound.goldInterest;
    return acc;
  }, accumulator);
}

function isReroll(next, prev) {
  const isGoldEqual = next.goldCurrent + GOLD_INCOME_RATE === prev.goldCurrent;
  if (next.roundOpponent) {
    return (
      isGoldEqual &&
      JSON.stringify(prev.champions.filter((champ) => !champ.board)) ===
        JSON.stringify(next.champions.filter((champ) => !champ.board))
    );
  }
  return (
    isGoldEqual &&
    JSON.stringify(prev.champions) === JSON.stringify(next.champions)
  );
}

export default function useInGameBenchmarking(gameData, { champions, set }) {
  const [stats, setStats] = useState({
    roundsWon: 0,
    roundsLost: 0,
    roundsWonDiff: 0,
    roundsWonAvg: 0,
    interestEarned: 0,
    interestAvg: 0,
    streakIncome: 0,
    level: 0,
    levelDiff: 0,
    levelAvg: 0,
    boardValue: 0,
    boardValueDiff: 0,
    boardValueAvg: 0,
    rerolls: 0,
    avgStars: 0,
    avgStarsDiff: 0,
    opponentAvgStars: 0,
    // Reroll calculations
    champions: gameData?.player.champions ?? [],
    goldCurrent: gameData?.player.goldCurrent ?? 0,
    roundOpponent: gameData?.roundOpponent ?? "",
    // Used to record time over time stats (wins, loss, etc.)
    rounds: {},
  });
  useEffect(() => {
    if (gameData) {
      const opponents = getOpponentData(gameData, champions, set).filter(
        (opponent) => opponent.health > 0,
      );
      const enemyLength = opponents.length;
      const player = getPlayerData(gameData, champions, set);
      const level = gameData.player.level;
      const opponentValues = opponents.reduce(
        (acc, cur) => calculateBoardValue(cur) + acc,
        0,
      );
      const avgStars = calculateAvgStars(player);
      setStats((prev) => {
        const next = clone(prev);
        // General
        const roundCurrent = gameData.roundStage + "_" + gameData.roundIndex;
        if (roundCurrent !== prev.round) next.round = roundCurrent;
        // Player
        next.champions = gameData.player.champions;
        generateRounds(next, player, gameData);
        const nextRoundsByPlayer = next.rounds[player.summonerName];
        const { goldStreak, goldInterest, roundsWon, roundsLost } =
          getAggStatsByPlayerRounds(nextRoundsByPlayer);
        // Opponents
        let opponentStars = 0;
        let opponentWins = 0;
        let opponentLevels = 0;
        for (const opponent of opponents) {
          // Levels
          opponentLevels += opponent.level;
          // Stars
          const stars = calculateAvgStars(opponent);
          if (stars) opponentStars += stars;
          // Rounds
          generateRounds(next, opponent, gameData);
          // Stats update for opponents
          const nextRoundsByOpponent = next.rounds[opponent.summonerName];
          const { roundsWon } = getAggStatsByPlayerRounds(nextRoundsByOpponent);
          opponentWins += roundsWon;
        }
        const avgLevel = enemyLength ? opponentLevels / enemyLength : 0;
        const levelDiff = level - avgLevel;
        const opponentAvgStars = enemyLength ? opponentStars / enemyLength : 0;
        const avgStarsDiff = avgStars - opponentAvgStars;
        const avgWins = enemyLength ? opponentWins / enemyLength : 0;
        const roundsWonDiff = roundsWon - avgWins;
        // Reroll calculations and update
        next.goldCurrent = gameData.player.goldCurrent;
        next.roundOpponent = gameData.roundOpponent;
        if (isReroll(next, prev)) next.rerolls += 1;
        const updates = {
          level,
          levelDiff,
          levelAvg: avgLevel,
          interestEarned: goldInterest,
          streakIncome: goldStreak,
          roundsWon,
          roundsLost,
          roundsWonDiff,
          roundsWonAvg: avgWins || 0,
        };
        // Update board values during minion rounds
        if (!gameData.roundOpponent) {
          const avgValue = enemyLength ? opponentValues / enemyLength : 0;
          const boardValue = calculateBoardValue(player);
          const boardValueDiff = boardValue - avgValue;
          updates.boardValueAvg = avgValue;
          updates.boardValue = boardValue;
          updates.boardValueDiff = boardValueDiff;
          updates.avgStars = avgStars;
          updates.opponentAvgStars = opponentAvgStars || 0;
          updates.avgStarsDiff = avgStarsDiff || 0;
        }
        // Merge next
        deepMerge(next, updates);
        if (deepEqual(prev, next)) return prev;
        return next;
      });
    }
  }, [champions, gameData, set]);
  return stats;
}
