import React, { useEffect, useState } from "react";

import {
  INT_LAST_PLAYED_PLAYER,
  INT_NEXT_PLAYER,
  INT_ROUND_RANGE,
  INT_SCALE_FACTOR,
} from "@/game-tft/constants-in-game.mjs";
import InGamePlayer from "@/game-tft/InGamePlayer.jsx";
import clone from "@/util/clone.mjs";
import { shallowEqual } from "@/util/deep-equal.mjs";
import deepMerge from "@/util/deep-merge.mjs";

// Init the Component's players array
function getPlayers(player, opponents) {
  const players = [];
  const allies = {};
  const alliesLength = player.alliesLength ?? 0;
  for (let i = 0; i < alliesLength; i += 1) {
    const { name } = player.allies[i];
    allies[name] = true;
  }
  const opponentsLength = opponents.length;
  for (let i = 0; i < opponentsLength; i += 1) {
    const { name, health, screenPos } = opponents[i];
    if (health <= 0 || allies[name] || screenPos.x < 0 || screenPos.y < 0)
      continue; // Exclude <= 0 HP players or allies from the result (players can be negative HP)
    const result = {
      name,
      health,
      roundsPlayedAgo: INT_NEXT_PLAYER,
      ...screenPos,
    };
    players.push(result);
  }
  return players;
}
// Delete players from the list if health is equal or below 0
function deletePlayers(players, idx = 0) {
  const length = players.length;
  for (let i = idx; i < length; i += 1) {
    const player = players[i];
    if (player.health > 0) continue;
    players.splice(i, 1);
    return deletePlayers(players, i);
  }
}
// Round ranges: key = players length, value = maximum round range
const ROUND_RANGES = {
  1: -1,
  2: 0,
  3: 1,
  4: 1,
  5: 1,
  6: 2,
  7: INT_ROUND_RANGE,
};

export default function InGameNextOpponentOverlay({
  t,
  wndAttributes,
  gameData,
  captureData,
}) {
  const { width, height } = wndAttributes;
  const { player, opponents, roundOpponent } = gameData;
  const isHidden = (opponents[0]?.screenPos.x ?? 0) < 0;
  const [state, setState] = useState({
    roundOpponent,
    players: getPlayers(player, opponents),
  });
  useEffect(() => {
    setState((prev) => {
      // Constants
      const nextLength = opponents.length;
      const prevLength = prev.players.length;
      // Update position + rounds played ago if its a valid player
      if (
        roundOpponent &&
        roundOpponent !== prev.roundOpponent &&
        prevLength &&
        nextLength
      ) {
        const nextPlayers = clone(prev.players);
        for (const opponent of opponents) {
          const idx = nextPlayers.findIndex((i) => i.name === opponent.name);
          if (idx === -1) continue; // findIndex indicates -1 as not found
          const nextPlayer = nextPlayers[idx];
          // If the player has 0 or less health, remove them from tracking
          if (nextPlayer.health <= 0) {
            nextPlayers.splice(idx, 1);
            continue;
          }
          nextPlayer.health = opponent.health;
          Object.assign(nextPlayer, opponent.screenPos); // Update position
          // Update rounds played ago
          const length = nextPlayers.length;
          const roundRange = ROUND_RANGES[length];
          if (nextPlayer.name === roundOpponent && length > 1) {
            nextPlayer.roundsPlayedAgo = INT_LAST_PLAYED_PLAYER;
          } else if (
            nextPlayer.roundsPlayedAgo >= roundRange ||
            (length !== prev.players.length && length < 3)
          ) {
            nextPlayer.roundsPlayedAgo = INT_NEXT_PLAYER;
          } else if (nextPlayer.roundsPlayedAgo >= 0) {
            nextPlayer.roundsPlayedAgo += 1;
          }
        }
        return {
          roundOpponent,
          players: nextPlayers,
        };
      }
      // Update player positions only
      const queue = {};
      let queueLength = 0;
      if (nextLength)
        for (let i = 0; i < prevLength; i += 1) {
          const prevOpponent = prev.players[i];
          const nextOpponent = opponents.find(
            (i) => i.name === prevOpponent.name,
          );
          if (
            !nextOpponent ||
            shallowEqual(nextOpponent.screenPos, {
              x: prevOpponent.x,
              y: prevOpponent.y,
            })
          )
            continue;
          // This queue is created because we might have some players who don't have any updates to perform while some do
          queueLength += 1;
          queue[i] = {
            health: nextOpponent.health,
            ...nextOpponent.screenPos,
          };
        }
      if (queueLength) {
        // If we have a queue of updates to perform, we clone the existing players and update their health + screen position values
        const nextPlayers = clone(prev.players);
        for (const idx in queue) deepMerge(nextPlayers[idx], queue[idx]);
        // After updating our players list, remove any players that are no longer alive
        deletePlayers(nextPlayers);
        return {
          roundOpponent,
          players: nextPlayers,
        };
      }
      return prev;
    });
  }, [opponents, player, roundOpponent]);

  // tracking
  useEffect(() => {
    captureData({
      name: "matchup tracking",
      action: "view",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isHidden
        ? null
        : state.players.map((player, idx) => (
            <InGamePlayer
              key={idx}
              t={t}
              player={player}
              height={height}
              width={width}
              scaleFactor={INT_SCALE_FACTOR}
            />
          ))}
    </div>
  );
}
