import { UNBUYABLE_CHAMPS } from "@/game-tft/constants.mjs";
import { RegExpTftSet } from "@/game-tft/get-trait-names.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import { devError } from "@/util/dev.mjs";

export const getPlayerData = (
  gameData,
  championStatic,
  selectedSet,
  setItems,
  sendToBE = false,
) => {
  const champions = getChampions(
    gameData.player,
    championStatic,
    selectedSet,
    setItems,
  );
  return {
    summonerName: gameData.player.name,
    health: gameData.player.health,
    level: gameData.player.level,
    expCur: gameData.player.expCur,
    expMax: gameData.player.expMax,
    winStreak: gameData.player.winStreak,
    gold: gameData.player.goldCurrent,
    goldPassive: gameData.player.goldPassive,
    goldInterest: gameData.player.goldInterest,
    goldStreak: gameData.player.goldStreak,
    itemsLength: gameData.player.itemsLength,
    items: gameData.player.itemsArray,
    screenPos: sendToBE ? undefined : gameData.player.screenPos,
    champions: champions,
    traits: getTraitsFromChampions(champions),
    room: gameData.player.room,
    isPlayer: true,
  };
};

const lowercaseKeys = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});

const sortByValue = (obj) => {
  return Object.entries(obj)
    .sort(([, a], [, b]) => b - a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
};

function getItemFromID(id, setItems) {
  if (!setItems || !id) return "";
  if (setItems) {
    return Object.values(setItems).filter(
      (item) => item.id === parseInt(id, 10),
    )[0]?.key;
  }
  return "";
}

const getChampionTraits = (
  champion,
  championStatic,
  selectedSet,
  champItems,
  setItems,
) => {
  if (!champion || !championStatic || !selectedSet) return null;

  const championStaticLower = lowercaseKeys(championStatic);
  const championFromData =
    championStaticLower[champion.toLowerCase().replace("_", "")];

  if (!championFromData) return null;

  const traits = championFromData?.origin.concat(championFromData?.class);
  traits?.forEach((trait, i) => (traits[i] = trait.toLowerCase()));

  if (champItems && setItems) {
    champItems.forEach((item) => {
      const traitFromItem = setItems[getItemFromID(item, setItems)]?.trait;
      if (traitFromItem) {
        traits.push(traitFromItem);
      }
    });
  }

  return traits;
};

const getChampions = (player, championStatic, selectedSet, setItems) => {
  if (!player) return [];
  const champions = [];
  for (let i = 0; i < player.championsLength; i++) {
    const champion = player.champions?.[i];
    // The championsLength does not guarantee that there will be champion at the array index within a loop
    if (typeof champion?.id !== "string") continue;
    const id = champion.id;
    if (!id) continue; // If we have an empty string, we can skip the champion static search below
    let unit = championStatic?.[id];
    // Check through the champion static object to ensure we haven't missed a possible unit match
    if (!unit)
      for (const key in championStatic) {
        const champion = championStatic[key];
        if (champion.apiKey !== id && champion.name !== id) continue;
        unit = champion;
        break;
      }
    const unitBySet = unit;
    if (!unitBySet) continue;
    champions.push({
      name: unit.name,
      id: unit.id,
      items: champion.itemsArray,
      traits: getChampionTraits(
        unit.id,
        championStatic,
        selectedSet,
        champion.itemsArray,
        setItems,
      ),
      cost: unitBySet.cost,
      star: champion.star,
      board: champion.board,
      boardPosition: champion.boardPosition,
    });
  }
  return champions;
};

const getTraitsFromChampions = (champions) => {
  const traits = {};
  const championsCounted = [];
  const boardChampions = champions.filter((champion) => champion.board);

  boardChampions.forEach((champion) => {
    if (!champion.traits || championsCounted.includes(champion.name)) return;
    champion.traits.forEach((trait) => {
      if (!traits[trait]) {
        traits[trait] = 1;
      } else {
        traits[trait] += 1;
      }
    });

    championsCounted.push(champion.name);
  });

  return sortByValue(traits);
};

export const getOpponentData = (
  gameData,
  championStatic,
  selectedSet,
  setItems,
  sendToBE = false,
) => {
  const opponents = [];
  for (let i = 0; i < gameData.opponentsLength; i++) {
    const opponent = gameData.opponents[i];
    const health = opponent.health;
    if (health <= 0) continue;
    const champions = getChampions(
      opponent,
      championStatic,
      selectedSet,
      setItems,
    );
    opponents.push({
      summonerName: opponent.name,
      screenPos: sendToBE ? undefined : opponent.screenPos,
      champions: champions,
      traits: getTraitsFromChampions(champions),
      health,
      level: opponent.level,
      winStreak: opponent.winStreak,
      items: opponent.itemsArray,
      itemsLength: opponent.itemsLength,
      room: opponent.room,
    });
  }
  return opponents;
};

export const calculateAvgStars = (player) => {
  let totalStars = 0;
  const champsOnBoard = player.champions.filter((champ) => champ.board);
  const length = champsOnBoard.length;
  if (!length) return totalStars;
  for (const champion of champsOnBoard) totalStars += champion.star;
  return totalStars / length;
};

export const calculateBoardValue = (player) => {
  let totalValue = 0;
  for (const champion of player.champions) {
    if (
      !champion.board ||
      UNBUYABLE_CHAMPS.some(
        (c) =>
          StaticTFT.getChampName(c)?.toLowerCase() ===
          champion.name?.toLowerCase(),
      )
    )
      continue;
    const cost = champion.cost || 1;
    const copies = Math.pow(3, champion.star - 1);
    const totalCost = copies * cost;
    totalValue += totalCost;
  }
  return totalValue;
};

export function modifyChampionStruct(player, champions, set, isPlayer = true) {
  // rename goldCurrent to gold
  player.gold = player.goldCurrent || 0;
  delete player.goldCurrent;
  // reuname name to summonerName
  player.summonerName = player.name || "";
  delete player.name;
  // rename itemsArray to items
  player.items = player.itemsArray || [];
  delete player.itemsArray;
  player.isPlayer = isPlayer;

  if (!Array.isArray(player?.champions)) return;
  for (const playerChampion of player.champions) {
    playerChampion.apiKey = playerChampion.id;
    const id = playerChampion.id.match(RegExpTftSet)?.[1] ?? "";
    // Extremely unlikely we receive a terribly named id from blitz-core
    if (!id)
      devError(
        "TFT Data Error: Unable to get the champion / unit ID, currently sending bad data",
      );
    playerChampion.id = id;
    playerChampion.name = id;
    // rename itemsArray to items
    playerChampion.items = playerChampion.itemsArray || [];
    delete playerChampion.itemsArray;
    const championBySet = champions[id];
    const traits = [];
    for (const i of [championBySet?.class, championBySet?.origin]) {
      if (!Array.isArray(i)) continue;
      traits.push(...i);
    }
    playerChampion.traits = traits.length ? traits : null; // Send null if there are no traits
    const cost = championBySet?.cost;
    if (typeof cost === "number") playerChampion.cost = cost;
  }
}
