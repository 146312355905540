import React, { createElement, useEffect, useMemo, useState } from "react";
import { styled } from "goober";

import {
  ROLLING_CHANCES,
  TOTAL_UNIT_COPIES,
  UNBUYABLE_CHAMPS,
} from "@/game-tft/constants.mjs";
import getChampionCenteredImage from "@/game-tft/get-champion-centered-image.mjs";
import { RegExpTftSet } from "@/game-tft/get-trait-names.mjs";
import { InGameOverlayToggles } from "@/game-tft/InGameOverlay.style.jsx";
import {
  DROPSHADOW_OVERLAY_CSS,
  InGameBox,
  StringToHighlight,
} from "@/game-tft/InGameShared.jsx";
import ItemImage from "@/game-tft/ItemImage.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import ToggleItem from "@/game-tft/ToggleItem.jsx";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import { translateTraits } from "@/game-tft/use-traits.mjs";
import clone from "@/util/clone.mjs";
import { devWarn } from "@/util/dev.mjs";

const STAR_POWER = {};
// Sets and gets a table of star powers that gets recalculated very often. This prevents recalcs for previously calculated star powers.
function getStarPower(star) {
  if (!STAR_POWER[star]) STAR_POWER[star] = Math.pow(3, star - 1);
  return STAR_POWER[star];
}
// Accumulate star powers for previously set keys (or set once)
function addStarPowerToTarget(target, key, star) {
  if (typeof target !== "object" || typeof key !== "string") {
    devWarn("Function addStarPowerToTarget has invalid arguments");
    return;
  }
  const unitKey = key.match(RegExpTftSet)?.[1];
  if (!unitKey) {
    devWarn(
      `Function addStarPowerToTarget RegExp match could not get a valid unit key. Incoming key: ${key}.`,
    );
    return;
  }
  if (target[unitKey]) {
    target[unitKey] += star;
  } else {
    target[unitKey] = star;
  }
}

function ToggleTraitsAndItems({
  t,
  champion,
  champions,
  unitStatsBySet,
  index,
  items,
  itemsLocalized,
  searchInput,
  set,
  setState,
  player,
  opponents,
}) {
  const [itemKey, setItemKey] = useState("");
  const champ = champions[champion];
  const championSplashUrl = getChampionCenteredImage(champion, set);

  // counts all champions
  const champTaken = {};
  for (const champ of player.champions) {
    const starPower = getStarPower(champ.star);
    addStarPowerToTarget(champTaken, champ.id, starPower);
  }
  for (const opponent of opponents) {
    for (const champ of opponent.champions) {
      const starPower = getStarPower(champ.star);
      addStarPowerToTarget(champTaken, champ.id, starPower);
    }
  }

  // subtract to see available copies
  const champAvailable = {};
  for (const [champ, count] of Object.entries(champTaken)) {
    const champCost = champions?.[champ]?.cost;
    const copiesInPool = TOTAL_UNIT_COPIES?.["cost-" + champCost] - count;
    champAvailable[champ] = copiesInPool;
  }

  // champ Rates
  const champRate = {};
  const setChamps = champions;
  for (const [champ, data] of Object.entries(setChamps)) {
    const champCost = data.cost;
    const sameCostChamps = Object.fromEntries(
      Object.entries(setChamps)
        .filter(([k]) => champions[k]?.cost === champCost)
        .filter(([k]) => !UNBUYABLE_CHAMPS?.includes(k)),
    );

    let sameCostChampPool = 0;
    Object.keys(sameCostChamps).forEach(
      (c) =>
        (sameCostChampPool += champAvailable[c]
          ? champAvailable[c]
          : TOTAL_UNIT_COPIES?.["cost-" + champCost]),
    );

    let count = champAvailable[champ]
      ? champAvailable[champ]
      : TOTAL_UNIT_COPIES?.["cost-" + champCost];
    if (UNBUYABLE_CHAMPS?.includes(champ)) count = 0;

    const currentChampProbability =
      ROLLING_CHANCES["level-" + player.level]?.["cost-" + champCost];

    champRate[champ] = currentChampProbability
      ? (count / sameCostChampPool) * currentChampProbability * 100
      : 0;
  }
  const dropChance = champRate[champion]?.toFixed(1);

  const traitClass = useMemo(() => champ?.class || [], [champ]);
  const traitOrigin = useMemo(() => champ?.origin || [], [champ]);
  const traits = useMemo(
    () =>
      traitClass.concat(traitOrigin).map((trait) => {
        const traitKey = StaticTFT.getTraitKey(trait, set);
        return {
          trait: traitKey,
          traitTranslated: translateTraits(t, traitKey, set),
        };
      }),
    [set, t, traitClass, traitOrigin],
  );
  const championItemsAll = useMemo(() => {
    if (!unitStatsBySet) return;
    for (const [name, stats] of Object.entries(unitStatsBySet))
      if (StaticTFT.getChampKey(name, set) === champion && stats.top_items)
        // The next memo modifies the original source, this needs to be deep cloned
        return clone(stats.top_items);
  }, [champion, set, unitStatsBySet]);
  const championItems = useMemo(
    () => championItemsAll?.slice(0, 5),
    [championItemsAll],
  );
  function onItemHover(itemKey) {
    return function handleItemHover() {
      setItemKey(itemKey);
    };
  }
  useEffect(() => {
    setItemKey("");
    return () => {
      setItemKey("");
    };
  }, [champion]);
  useEffect(() => {
    if (itemKey) {
      setState((prev) => {
        const next = { ...prev };
        const nextIndex = index + 1;
        const Component = createElement(ToggleItem, {
          key: nextIndex,
          index: nextIndex,
          id: itemKey,
          items,
          itemsLocalized,
          set,
          setState,
        });
        if (next.tooltips.length >= nextIndex) {
          if (next.tooltips.length > nextIndex)
            next.tooltips = next.tooltips.slice(0, nextIndex);
          next.tooltips.splice(nextIndex, 1, Component);
        } else {
          next.tooltips.push(Component);
        }
        return next;
      });
    }
  }, [index, itemKey, items, itemsLocalized, set, setState]);
  return (
    <Container size="small">
      <ImageFilter />
      <Image src={championSplashUrl} alt={champion} />
      <Content>
        <div className="flex column gap-2">
          <ChampionName>
            <StringToHighlight source={champ?.name} match={searchInput} />
          </ChampionName>
          <Traits>
            {traits.map(({ trait, traitTranslated }) => (
              <Trait key={trait}>
                <TypeIcon name={trait} size={20} />
                {traitTranslated}
              </Trait>
            ))}
          </Traits>
        </div>
        <Items>
          {championItems?.map((item) => (
            <ItemImage
              key={item}
              itemKey={item}
              selectedSet={set}
              size="36px"
              onMouseEnter={onItemHover(item)}
            />
          ))}
        </Items>
        {!Number.isNaN(dropChance) && dropChance !== 0 && (
          <>
            <div className="probability">
              <div className="text">{dropChance}%</div>
            </div>
            <div className="probabilityAfter"></div>
          </>
        )}
      </Content>
      <InGameOverlayToggles>
        {!!itemKey && (
          <ToggleItem
            id={itemKey}
            items={items}
            itemsLocalized={itemsLocalized}
            set={set}
          />
        )}
      </InGameOverlayToggles>
    </Container>
  );
}

export default ToggleTraitsAndItems;

// Styles
const Container = styled(InGameBox)`
  padding: var(--sp-3);
  position: relative;
  border-radius: var(--br);
  ${DROPSHADOW_OVERLAY_CSS}
`;
const Traits = styled("div")`
  display: flex;
  flex-flow: column;
  gap: var(--sp-2);
`;
const Trait = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-1);
  font-size: var(--sp-3);
`;
const Items = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-1);
`;
const ImageFilter = styled("div")`
  background: linear-gradient(90deg, #000000 -2.34%, rgba(0, 0, 0, 0) 89.47%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: var(--br);
`;
const Image = styled("img")`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  object-fit: cover;
  width: 100%;
  height: 100% !important;
  border-radius: var(--br);
`;
const PROBABILITY_DIMENSIONS = {
  w: 75,
  h: 40,
};
const Content = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-8);
  z-index: 0;
  & .probability {
    position: absolute;
    bottom: 0;
    right: 0;
    border-bottom: ${PROBABILITY_DIMENSIONS.h}px solid var(--shade9);
    border-left: 25px solid transparent;
    width: ${PROBABILITY_DIMENSIONS.w}px;
    height: 0;
    z-index: 1;
  }
  & .probabilityAfter {
    position: absolute;
    bottom: var(--sp-px);
    right: var(--sp-px);
    border-bottom: ${PROBABILITY_DIMENSIONS.h}px solid var(--yellow);
    border-left: 25px solid transparent;
    width: ${PROBABILITY_DIMENSIONS.w}px;
    height: 0;
  }
  & .probability .text {
    position: absolute;
    top: ${PROBABILITY_DIMENSIONS.h / 2}px;
    left: ${PROBABILITY_DIMENSIONS.w / 2}px;
    transform: translate(-100%, -50%);
  }
`;
const ChampionName = styled("div")`
  color: var(--shade1);
`;
