import React, { useEffect } from "react";
import { styled } from "goober";

import {
  INT_ASPECT_RATIO,
  INT_COST_QUOTIENT,
  INT_MAX_PLAYER_LEVEL,
  INT_MIN_PLAYER_HP,
  INT_MIN_X_Y,
  INT_SCALE_FACTOR,
} from "@/game-tft/constants-in-game.mjs";
import { autoScaleUp } from "@/game-tft/InGameShared.jsx";
import ChevronRightIcon from "@/inline-assets/chevron-right.svg";
import HextechMatchGoldIcon from "@/inline-assets/hextech-match-gold.svg";

function InGameLevelingOverlay({
  t,
  wndAttributes,
  buyXpScreenPos,
  player,
  captureData,
}) {
  const scaleFactor = INT_SCALE_FACTOR;
  const { width, height } = wndAttributes;
  const { x, y } = buyXpScreenPos;
  const { health, level, expCur, expMax, goldCurrent } = player;

  // tracking
  useEffect(() => {
    captureData({
      name: "leveling",
      action: "view",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !width ||
    !height || // If width or height is 0, core probably needs updating
    x < INT_MIN_X_Y ||
    x >= width ||
    y < INT_MIN_X_Y ||
    y >= height || // If x or y values are highly inaccurate, core probably needs updating
    level >= INT_MAX_PLAYER_LEVEL ||
    health <= INT_MIN_PLAYER_HP // Players can go below the MIN value
  ) {
    return null;
  }
  const cost = expMax - expCur;
  const actualCost = cost + (cost % INT_COST_QUOTIENT);
  const bottomPosition =
    (height - y - 63 * Math.pow(height / INT_ASPECT_RATIO, 0.1)) / scaleFactor;
  const rightPosition =
    (width - x + (10 * height) / INT_ASPECT_RATIO) / scaleFactor;
  // Render
  return (
    <LevelingOverlay
      className={actualCost / goldCurrent > 1 && "hide"}
      style={{
        bottom: bottomPosition.toFixed(2) + "px",
        right: rightPosition.toFixed(2) + "px",
        transform: `scale(${autoScaleUp()})`,
      }}
    >
      <div id="expContainer">
        <HextechMatchGoldIcon className="coin" />
        <span
          className={[
            "costGroup",
            cost % INT_COST_QUOTIENT === 0 ? "green" : "red",
          ].join(" ")}
        >
          <span className="actualCost">{actualCost}</span>/
          <span className="cost">{cost}</span>
        </span>
        {t("tft:toLevel", "to level")}
      </div>
      <div id="costContainer">
        <HextechMatchGoldIcon className="coin" />
        <span className="gold">{goldCurrent}</span>
        <ChevronRightIcon id="arrowRight" />
        <span className="goldAfter">{goldCurrent - actualCost}</span>
      </div>
    </LevelingOverlay>
  );
}

export default InGameLevelingOverlay;

const LevelingOverlay = styled("div")`
  background: var(--shade8);
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  width: fit-content;
  border-radius: var(--br);
  align-items: center;
  box-shadow: 0 var(--sp-1) var(--sp-1) var(--shade10-25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: var(--sp-1);
  align-items: flex-start;
  padding: var(--sp-2_5) var(--sp-3);
  line-height: var(--sp-4_5);
  position: fixed;
  transform-origin: top right;
  z-index: 1;
  &.hide {
    display: none !important;
  }
  & .coin {
    width: var(--sp-3_5);
    height: var(--sp-3_5);
    fill: var(--yellow);
    display: inline-block;
    margin-right: var(--sp-2);
  }
  & .actualCost {
    font-size: 1.1rem;
  }
  & .cost {
    font-size: 0.65rem;
    padding-right: var(--sp-0_5);
  }
  & .actualCost,
  & .cost {
    font-weight: bold;
  }
  & .gold,
  & .goldAfter {
    line-height: initial;
    font-size: 0.75rem;
  }
  & #arrowRight {
    display: inline-block;
    width: var(--sp-3);
    height: var(--sp-3);
    margin-top: var(--sp-0_5);
  }
  & .green {
    color: #2f9a5c;
  }
  & .red {
    color: #b11a1a;
  }
  & .costGroup {
    margin-right: 3px;
  }
  & #expContainer {
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
  }
  & #costContainer {
    display: flex;
    align-items: center;
  }
`;
