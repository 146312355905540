import React from "react";
import { styled } from "goober";

import {
  FLOAT_Y_PLAYER_POSITION_EXPONENT,
  INT_ASPECT_RATIO,
  INT_NEXT_PLAYER,
  INT_ROUND_RANGE,
  INT_X_SCALE_MULTIPLIER,
  INT_Y_OFFSET,
} from "@/game-tft/constants-in-game.mjs";

const translations = [
  ["tft:common:nextUp", "Next Up"],
  ["common:lastPlayed", "Last Played"],
  ["tft:common:oneRoundAgo", "1 Round Ago"],
  ["tft:common:twoRoundsAgo", "2 Rounds Ago"],
  ["tft:common:threeRoundsAgo", "3 Rounds Ago"],
];

export default function InGamePlayer({
  t,
  player,
  width,
  height,
  scaleFactor,
}) {
  const scaledWidth = width / scaleFactor;
  const scaledHeight = height / scaleFactor;
  const scaledX = player.x / scaleFactor;
  const scaledY = player.y / scaleFactor;
  const topPosition =
    scaledY +
    INT_Y_OFFSET *
      Math.pow(
        scaledHeight / INT_ASPECT_RATIO,
        FLOAT_Y_PLAYER_POSITION_EXPONENT,
      );
  const rightPosition =
    scaledWidth -
    scaledX -
    (INT_X_SCALE_MULTIPLIER * scaledHeight) / INT_ASPECT_RATIO;
  let labelText = null;
  for (let i = 0; i <= INT_ROUND_RANGE; i += 1) {
    if (player.roundsPlayedAgo === i) {
      labelText = t(translations[i + 1]);
      break;
    }
  }
  if (!labelText) labelText = t(translations[0]);
  if (
    rightPosition < 0 ||
    rightPosition >= width ||
    topPosition < 0 ||
    topPosition >= height
  ) {
    return null;
  }
  return (
    <NextUpOverlay
      className={player.roundsPlayedAgo !== INT_NEXT_PLAYER && "roundsAgo"}
      style={{
        top: `${topPosition}px`,
        right: `${rightPosition}px`,
        transform: `translate(50%,50%) scale(${
          scaledHeight / INT_ASPECT_RATIO
        })`,
      }}
    >
      {labelText}
    </NextUpOverlay>
  );
}

const NextUpOverlay = styled("div")`
  position: fixed;
  display: flex;
  flex-direction: row;
  width: fit-content;
  background: var(--shade7);
  border-radius: var(--sp-25);
  border: var(--sp-0_5) solid #ae925d;
  box-shadow: 0 var(--sp-1) var(--sp-1) rgba(0, 0, 0, 0.25);
  padding: 0 var(--sp-2_5);
  font-size: 0.85rem;
  user-select: none;
  transform-origin: center;
  &.roundsAgo {
    color: var(--shade0-75);
  }
  &:hover {
    background: var(--shade6);
    cursor: default;
  }
`;
