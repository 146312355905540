import React, { useMemo } from "react";
import { styled } from "goober";

import { InGameBox } from "@/game-tft/InGameShared.jsx";
import ItemImage from "@/game-tft/ItemImage.jsx";
import AddIcon from "@/inline-assets/add.svg";

function ToggleItem({ id, items, itemsLocalized, set }) {
  const item = useMemo(() => {
    const itemsBySet = items[set];
    if (!itemsBySet) return null;
    for (const key in itemsBySet) {
      const item = itemsBySet[key];
      if (item.key === id) {
        const title = itemsLocalized[item.key]?.name ?? item.name;
        const description =
          itemsLocalized[item.key]?.description ?? item.description;
        const [combinedItemKey, basicItemKey, componentItemKey] = [
          item.key,
        ].concat(item.buildsFrom);

        return {
          title,
          description,
          tier: item.tier,
          basicItemKey,
          componentItemKey,
          combinedItemKey,
        };
      }
    }
    return null;
  }, [id, items, itemsLocalized, set]);
  if (!item) return null;
  return (
    <Container>
      <div className="header">
        <ItemImage
          itemKey={item.combinedItemKey}
          selectedSet={set}
          size="60px"
        />
        <div className="content">
          <div className="title">{item.title}</div>
          <div className="items">
            {!!item.basicItemKey && !!item.componentItemKey && (
              <>
                <ItemImage
                  itemKey={item.basicItemKey}
                  selectedSet={set}
                  size="30px"
                />
                <AddIcon />
                <ItemImage
                  itemKey={item.componentItemKey}
                  selectedSet={set}
                  size="30px"
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="description">{item.description}</div>
    </Container>
  );
}

export default ToggleItem;

const Container = styled(InGameBox)`
  width: calc(var(--sp-5) * 15);
  padding: var(--sp-3);
  display: flex;
  gap: var(--sp-2);
  flex-direction: column;
  & > div.header {
    display: flex;
    gap: var(--sp-2);
  }
  & > div.description {
    color: var(--shade1);
  }
  & > div.description,
  & div.title {
    font-size: var(--sp-3);
  }
  & div.content {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1);
  }
  & div.content > div.items {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
  }
  & div.content > div.items > svg {
    width: var(--sp-4);
    height: var(--sp-4);
  }
`;
