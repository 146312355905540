import React, { createElement, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { styled } from "goober";

import { appURLs } from "@/app/constants.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { Champion } from "@/game-tft/CompsFilterChampions.style.jsx";
import { UNBUYABLE_CHAMPS } from "@/game-tft/constants.mjs";
import {
  DROPSHADOW_OVERLAY_CSS,
  InGameBox,
  StringToHighlight,
} from "@/game-tft/InGameShared.jsx";
import {
  getLoadedDiceTable,
  getUnitsByUnitTable,
} from "@/game-tft/loaded-dice-utils.mjs";
import ToggleTraitsAndItems from "@/game-tft/ToggleTraitsAndItems.jsx";
import orderArrayBy from "@/util/order-array-by.mjs";

export default function ToggleLoadedDice({
  t,
  champion,
  champions,
  championsStats,
  isSkinsEnabled,
  items,
  itemsLocalized,
  searchInput,
  set,
  setState,
  index,
  player,
  opponents,
}) {
  const [championSelected, setChampionSelected] = useState("");
  const loadedDiceTable = getLoadedDiceTable({
    champStatic: champions,
    selectedSet: set,
    itemStatic: items,
  });
  const currentUnitTable = loadedDiceTable[champion];
  const [units, level] = getUnitsByUnitTable({
    units: currentUnitTable,
    champions,
    set,
  });
  const currentUnit = champions[champion];
  const currentUnitBySet = currentUnit;
  const currentUnitName = currentUnit?.name;
  const unitsWithMatchingTraits = useMemo(() => {
    const results = [];
    for (const unit of units) {
      const currentUnit = champions[unit.key];
      const unitBySet = currentUnit;
      if (!unitBySet || UNBUYABLE_CHAMPS.includes(unit.key)) continue;
      results.push({
        name: currentUnit.name,
        key: currentUnit.apiKey,
        ...unitBySet,
      });
    }
    orderArrayBy(results, ["cost", "name"], ["desc", "asc"]);
    return results;
  }, [units, champions]);
  function onChampionHover(championKey) {
    return function handleChampionHover() {
      setChampionSelected(championKey);
    };
  }
  useEffect(() => {
    setChampionSelected("");
  }, [champion]);
  useEffect(() => {
    if (championSelected) {
      setState((prev) => {
        const next = { ...prev };
        const nextIndex = index + 1;
        const Component = createElement(ToggleTraitsAndItems, {
          key: nextIndex,
          index: nextIndex,
          champion: championSelected,
          champions,
          championsStats,
          items,
          itemsLocalized,
          set,
          setState,
          player,
          opponents,
          t,
        });
        if (next.tooltips.length >= nextIndex) {
          if (next.tooltips.length > nextIndex)
            next.tooltips = next.tooltips.slice(0, nextIndex);
          next.tooltips.splice(nextIndex, 1, Component);
        } else {
          next.tooltips.push(Component);
        }
        return next;
      });
    }
  }, [
    championSelected,
    champions,
    championsStats,
    index,
    items,
    itemsLocalized,
    opponents,
    player,
    set,
    setState,
    t,
  ]);
  if (!currentUnitBySet || !unitsWithMatchingTraits.length) return null;
  return (
    <InGameBoxModified>
      <div>
        <img
          src={`${appURLs.CDN}/blitz/tft/overlays/loadeddice.png`}
          alt={champion}
        />
        <div>
          <Trans i18nKey="overlays:tft.useLoadedDice">
            At level <StringToHighlight source={level} />, use loaded dice on
            these units for the best chance of getting{" "}
            <StringToHighlight source={currentUnitName} match={searchInput} />.
          </Trans>
        </div>
      </div>
      <Champions>
        {unitsWithMatchingTraits.map((unit, idx) => (
          <Champion key={idx} onMouseEnter={onChampionHover(unit.key)}>
            <ChampionImage
              champKey={unit.key}
              cost={unit.cost}
              size={33}
              set={set}
              skinSetting={isSkinsEnabled}
            />
          </Champion>
        ))}
      </Champions>
    </InGameBoxModified>
  );
}

const InGameBoxModified = styled(InGameBox)`
  position: relative;
  width: calc(var(--sp-32) * 2);
  padding: var(--sp-3);
  gap: var(--sp-4);
  display: flex;
  flex-direction: column;
  color: var(--shade1);
  & > div:first-of-type > img {
    width: var(--sp-13);
    height: var(--sp-13);
  }
  & > div:first-of-type > div {
    font-size: var(--sp-3);
  }
  & > div:first-of-type {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
  }
  ${DROPSHADOW_OVERLAY_CSS}
`;
const Champions = styled("div")`
  display: flex;
  gap: var(--sp-1_5);
  flex-flow: row wrap;
`;
