import React, { forwardRef, useEffect, useRef, useState } from "react";
import { css, styled } from "goober";

import {
  QUEUE_TYPES,
  RANK_SYMBOL_TO_STR,
} from "@/game-lol/constants/constants.mjs";
import getRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import { setOverlayInGameSetting } from "@/game-tft/actions.mjs";
import BlitzLogoWordmarkIcon from "@/inline-assets/blitz-logo-wordmark.svg";
import IncomeIcon from "@/inline-assets/hextech-income.svg";
import SettingsIcon from "@/inline-assets/settings.svg";
import DraggableUnit from "@/shared/DraggableUnit.jsx";

const ranks = Object.getOwnPropertySymbols(RANK_SYMBOL_TO_STR).map((i) => {
  const item = RANK_SYMBOL_TO_STR[i];
  const id = item.key;
  return {
    [id]: {
      id,
      name: item.t.fallback,
    },
  };
});

const Header = ({ t, isOpen, setIsOpen, handleSettingsPanel }) => {
  return (
    <div
      className={`benchmarkingOverlayHeader ${isOpen ? "open" : "closed"}`}
      onClick={(e) => {
        if (!isOpen) setIsOpen(true);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {!isOpen ? (
        <div className="benchmarkingHeader">
          {t("tft:benchmarking.benchmarks", "BENCHMARKS")}
        </div>
      ) : (
        <div className="benchmarkingHeader">
          <BlitzLogoWordmarkIcon />
        </div>
      )}
      <div className="benchmarkingIcons">
        <SettingsIcon
          className="settings_gear"
          onClick={(e) => {
            handleSettingsPanel();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        />
      </div>
    </div>
  );
};

const Content = ({
  t,
  isOpen,
  benchmarkingSettings,
  matchInfo,
  summonerInfo,
  stats,
  roundIndex,
  roundStage,
  subscriber,
  isLocked,
  gamesLocked,
  PlayedMoreThanFiveGames,
  // Static
  income,
}) => {
  const interestStats = (() => {
    if (!income?.interestEarned) return;
    const interestEarned = { ...income?.interestEarned };
    if (interestEarned.average) {
      delete interestEarned.average;
    }
    const rounds = Object.keys(interestEarned).sort((a, b) => {
      const [roundA, matchA] = a.split("_").map(Number);
      const [roundB, matchB] = b.split("_").map(Number);
      if (roundA !== roundB) {
        return roundA - roundB;
      }
      return matchA - matchB;
    });
    let total = 0;
    for (const round of rounds) {
      total += interestEarned[round];
      interestEarned[round] = total;
    }
    return interestEarned;
  })();
  const {
    roundsWon,
    roundsLost,
    roundsWonDiff,
    roundsWonAvg,
    interestEarned,
    streakIncome,
    level,
    levelDiff,
    levelAvg,
    boardValue,
    boardValueDiff,
    boardValueAvg,
    rerolls,
    avgStars,
    avgStarsDiff,
    opponentAvgStars,
  } = stats;

  const queueId =
    matchInfo?.queueId ||
    Object.keys(QUEUE_TYPES).find(
      (key) => QUEUE_TYPES[key] === "RANKED_TEAMFIGHT_TACTICS",
    );
  const rankTier = summonerInfo?.leagues?.find(
    (league) => league.queue?.name === "RANKED_TFT",
  )?.tier;
  const summonerRank =
    rankTier && Object.keys(ranks).includes(rankTier.toLowerCase())
      ? rankTier
      : "GOLD";

  const isHyperRoll = QUEUE_TYPES[queueId] === "TEAMFIGHT_TACTICS_HYPER_ROLL";

  const curRound =
    roundStage && roundIndex ? roundStage + "_" + roundIndex : "1_2";
  const interestTarget = interestStats?.[curRound] || 0;
  const interestEarnedDiff = interestEarned - interestTarget;

  const RankIcon = getRankIcon(summonerRank.toLowerCase());

  return (
    <div className={`contentContainer ${isOpen ? "open" : "closed"}`}>
      <div className="benchmarkingContent">
        {(benchmarkingSettings.roundsWon ||
          benchmarkingSettings.level ||
          benchmarkingSettings.boardValue ||
          benchmarkingSettings.avgUnitStars) && (
          <p
            className={`sectionLabel ${
              PlayedMoreThanFiveGames ? "shrink" : ""
            }`}
          >
            {t("tft:benchmarking.vsLobby", "VS LOBBY")}
          </p>
        )}
        <div className="sectionInner">
          {benchmarkingSettings.roundsWon && (
            <ContentRow
              name={t("tft:rounds", "Rounds")}
              value={`${roundsWon}${t(
                "val:outcomes.winShort",
                "W",
              )} ${roundsLost}${t("val:outcomes.lossShort", "L")}`}
              badgeData={{
                data: `${
                  roundsWonAvg === 0
                    ? roundsWonAvg?.toFixed()
                    : roundsWonAvg?.toFixed(1)
                }${t("val:outcomes.winShort", "W")}`,
                level: roundsWonDiff >= 0 ? "rise" : "dip",
              }}
            />
          )}
          {benchmarkingSettings.level && !isHyperRoll && (
            <ContentRow
              name={t("tft:benchmarking.lvl", "Lvl")}
              value={level}
              badgeData={{
                data:
                  levelAvg === 0 ? levelAvg?.toFixed() : levelAvg?.toFixed(1),
                level: levelDiff >= 0 ? "rise" : "dip",
              }}
            />
          )}
          {benchmarkingSettings.boardValue && (
            <ContentRow
              name={t("tft:benchmarking.boardValue", "Board value")}
              value={boardValue}
              type="gold"
              proStat={!subscriber}
              isLocked={isLocked}
              gamesLocked={gamesLocked}
              badgeData={{
                data: Math.round(boardValueAvg),
                level: boardValueDiff >= 0 ? "rise" : "dip",
              }}
            />
          )}
          {benchmarkingSettings.avgUnitStars && (
            <ContentRow
              name={t("tft:benchmarking.avgUnitStars", "Avg Unit Stars")}
              value={avgStars?.toFixed(1)}
              badgeData={{
                data:
                  opponentAvgStars === 0
                    ? opponentAvgStars?.toFixed()
                    : opponentAvgStars?.toFixed(1),
                level: avgStarsDiff >= 0 ? "rise" : "dip",
              }}
            />
          )}
        </div>
        {benchmarkingSettings.interestEarned && !isHyperRoll && (
          <>
            <hr />
            <p
              className={`sectionLabel ${
                PlayedMoreThanFiveGames ? "shrink" : ""
              }`}
            >
              {`${t("lol:vs", "vs")} ${summonerRank}`}
              <RankIcon />
            </p>
          </>
        )}
        <div className="sectionInner">
          {benchmarkingSettings.interestEarned && !isHyperRoll && (
            <ContentRow
              name={t("tft:interest", "Interest")}
              value={interestEarned}
              type="gold"
              badgeData={{
                data: Math.round(interestTarget),
                level: interestEarnedDiff >= 0 ? "rise" : "dip",
              }}
            />
          )}
        </div>
        {(benchmarkingSettings.rerolls || benchmarkingSettings.streakIncome) &&
          !isHyperRoll && <hr />}
        <div className="sectionInner">
          {benchmarkingSettings.rerolls && (
            <ContentRow
              name={t("tft:benchmarking.rerolls", "Rerolls")}
              value={rerolls + "x"}
            />
          )}
          {benchmarkingSettings.streakIncome && !isHyperRoll && (
            <ContentRow
              name={t("tft:benchmarking.streakIncome", "Streak Income")}
              value={streakIncome}
              type="gold"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ContentRow = ({ name, value, badgeData, type }) => {
  return (
    <div className="row">
      <p className="statName">{name}</p>
      <div className="right">
        <p className={`statValue ${badgeData?.level}`}>
          {type === "gold" && <IncomeIcon className="gold" />}
          {value}
        </p>
        {badgeData && (
          <div
            className="badge"
            style={{
              color: "white",
            }}
          >
            <p className="badgeData">/ {badgeData.data}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const OFFSET_RATIO = {
  x: 1492.453125 / 1900,
  y: 728.65625 / 1000,
};

export default function InGameOverlayBenchmarking({
  t,
  stats,
  roundIndex,
  roundStage,
  wndAttributes,
  benchmarkingSettings,
  subscriber,
  summonerInfo,
  matchInfo,
  handleSettingsPanel,
  champions,
  income,
  set,
  captureData,
}) {
  // tracking
  useEffect(() => {
    captureData({
      name: "benchmarking",
      action: "view",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refDraggable = useRef(null);
  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => setIsOpen((prev) => !prev);
  const { scaleFactor, height, width } = wndAttributes;
  // Calcs
  const MatchesPlayedData =
    JSON.parse(localStorage.getItem("MatchesPlayedData")) || {};
  const PlayedMoreThanFiveGames =
    JSON.parse(localStorage.getItem("PlayedMoreThanFiveGames")) || false;
  const matchesPlayed = Object.keys(MatchesPlayedData).length;
  const isLocked = matchesPlayed
    ? Math.ceil(matchesPlayed / 3) % 2 === 0
    : false;
  const gamesLocked = isLocked ? 4 - (((matchesPlayed - 1) % 6) + 1 - 3) : 0;
  const { position } = benchmarkingSettings;
  const undraggedPosition =
    position && (position.x !== 0 || position.y !== 0)
      ? css`
          position: absolute;
          top: ${position.y}px;
          left: ${position.x}px;
        `
      : css`
          position: absolute;
          top: ${OFFSET_RATIO.y * height}px;
          left: ${OFFSET_RATIO.x * width}px;
        `;
  return (
    <DraggableUnit
      draggableAreaRef={refDraggable}
      undraggedPosition={undraggedPosition}
      onDragEnd={(x, y) =>
        setOverlayInGameSetting(["benchmarkingOverlay", "position"], { x, y })
      }
    >
      <Benchmarking
        ref={refDraggable}
        style={{
          transformOrigin: "top left",
          transform: `scale(${
            ((benchmarkingSettings.size?.value ?? 100) / 100) * scaleFactor
          })`,
        }}
        $opacity={(benchmarkingSettings.opacity?.value ?? 100) / 100}
        className="benchmarkingOverlay"
      >
        <Header
          t={t}
          isOpen={isOpen}
          setIsOpen={handleToggle}
          handleSettingsPanel={handleSettingsPanel}
        />
        <Content
          t={t}
          isOpen={isOpen}
          benchmarkingSettings={benchmarkingSettings}
          stats={stats}
          roundIndex={roundIndex}
          roundStage={roundStage}
          matchInfo={matchInfo}
          subscriber={subscriber}
          summonerInfo={summonerInfo}
          isLocked={isLocked}
          gamesLocked={gamesLocked}
          PlayedMoreThanFiveGames={PlayedMoreThanFiveGames}
          // Static
          champions={champions}
          income={income}
          set={set}
        />
      </Benchmarking>
    </DraggableUnit>
  );
}

const Benchmarking = styled("div", forwardRef)`
  min-width: var(--sp-48);
  font-size: var(--sp-3);
  font-weight: 700;
  backdrop-filter: blur(var(--sp-2));
  filter: drop-shadow(0 var(--sp-0_5) var(--sp-1) rgba(0, 0, 0, 0.1));
  border-radius: var(--br);
  transform-origin: bottom right;
  cursor: pointer;
  transition: background 0.2s;
  background: rgba(14, 16, 21, ${({ $opacity }) => $opacity});
  &:hover {
    background: rgba(
      14,
      16,
      21,
      ${({ $opacity }) => $opacity + 0.25}
    ) !important;
  }
  & .benchmarkingHeader {
    display: flex;
    align-items: center;
    grid-gap: var(--sp-2_5);
    letter-spacing: 0.12em;
    text-transform: capitalize;
    margin-left: var(--sp-0_5);
  }
  & .benchmarkingHeader > svg {
    width: var(--sp-18);
    height: var(--sp-4);
  }
  & .benchmarkingOverlayHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: var(--sp-2_5);
    background-color: rgba(8, 9, 13, 0.25);
    border-radius: 5px 5px 0 0;
    padding: var(--sp-4) var(--sp-3);

    /* transition: padding 0.3s; */
  }
  & .benchmarkingOverlayHeader.open {
    padding: var(--sp-2) var(--sp-3);
  }
  & .benchmarkingIcon {
    width: var(--sp-6);
    height: var(--sp-6);
  }
  & .benchmarkingIcons {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    color: var(--shade1);
  }
  & .benchmarkingIcons svg {
    transition: transform 0.3s;
  }
  & .benchmarkingIcons .arrow:hover {
    cursor: pointer;
    color: var(--shade0);
  }
  & .benchmarkingIcons .settings_gear {
    width: var(--sp-4);
    height: var(--sp-4);
    color: var(--shade0);
    display: none;
  }
  &.benchmarkingOverlay:hover .benchmarkingOverlayHeader.open .settings_gear {
    display: block;
  }
  & .benchmarkingIcons .settings_gear:hover {
    cursor: pointer;
    color: white;
  }
  &.benchmarkingOverlay .contentContainer {
    /* transition: max-height 0.3s; */
    max-height: 0;
  }
  &.benchmarkingOverlay .contentContainer.open {
    max-height: 500px;
  }
  & .benchmarkingContent {
    display: flex;
    flex-direction: column;
    padding: var(--sp-3);
  }
  & .contentContainer.closed .benchmarkingContent {
    display: none;
  }
  & .benchmarkingContent .sectionLabel {
    color: rgba(255, 255, 255, 0.75);
    font-weight: 600;
    font-size: var(--sp-2_5);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: var(--sp-0_5);
    display: flex;
    align-items: center;
    gap: var(--sp-1);
    text-shadow: 0 var(--sp-px) 0 var(--shade10-15);
  }
  & .benchmarkingContent .sectionLabel svg {
    width: var(--sp-4);
    height: var(--sp-4);
  }
  & .benchmarkingContent .sectionLabel.shrink {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition:
      height 0.2s,
      opacity 0.2s;
  }
  & .benchmarkingContent .sectionInner {
    display: flex;
    flex-direction: column;
    grid-gap: var(--sp-1);
  }
  & .benchmarkingContent:hover .sectionLabel.shrink {
    height: var(--sp-3_5);
    opacity: 1;
    overflow: visible;
  }
  & .benchmarkingContent hr {
    width: 110%;
    margin-left: -5.2%;
    border: none;
    border-bottom: var(--sp-px) solid var(--shade0-15);
    margin-top: var(--sp-2);
    margin-bottom: var(--sp-2);
  }
  &.benchmarkingOverlay .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
  }
  &.benchmarkingOverlay .right {
    display: flex;
    align-items: flex-start;
    grid-gap: var(--sp-1);
    letter-spacing: 0.05em;
  }
  &.benchmarkingOverlay .right .gold {
    width: var(--sp-3);
    height: var(--sp-3);
    display: inline-block;
    margin-right: var(--sp-1_5);
  }
  &.benchmarkingOverlay .statName {
    color: white;
    font-weight: 600;
    font-size: var(--sp-3);
    line-height: var(--sp-5);
    text-shadow: 0 var(--sp-px) 0 var(--shade10-25);
    text-transform: uppercase;
    letter-spacing: 0.01em;
    display: flex;
    gap: var(--sp-0_5);
    align-items: center;
  }
  &.benchmarkingOverlay .statName svg {
    width: 15px;
    height: 15px;
  }
  &.benchmarkingOverlay .right svg.lock {
    width: var(--sp-5);
    height: var(--sp-5);
    filter: drop-shadow(0 var(--sp-px) var(--sp-px) var(--shade10-25));
    margin-right: calc(-1 * var(--sp-0_5));
  }
  &.benchmarkingOverlay .statValue {
    display: flex;
    align-items: center;
    color: white;
    font-size: var(--sp-3_5);
    font-weight: 700;
    text-shadow: 0 var(--sp-px) 0 var(--shade10-25);
  }
  &.benchmarkingOverlay .statValue.rise {
    color: #30d9d4;
  }
  &.benchmarkingOverlay .statValue.dip {
    color: #e44c4d;
  }
  & .down-filled-arrow {
    background: #e44c4d;
    width: 9.33px;
    height: 5.83px;
  }
  &.benchmarkingOverlay .badge {
    display: flex;
    grid-gap: var(--sp-1);
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    min-width: var(--sp-7_5);
  }
  &.benchmarkingOverlay .badgeData {
    font-weight: 600;
    font-size: var(--sp-3_5);
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    text-shadow: 0 var(--sp-px) 0 var(--shade10-25);
  }
`;
