import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { css, styled } from "goober";

import { setOverlayInGameSetting } from "@/game-tft/actions.mjs";
import { ItemContainer } from "@/game-tft/CommonComponents.jsx";
import { BASIC_ITEMS } from "@/game-tft/constants.mjs";
import { InGameBox } from "@/game-tft/InGameShared.jsx";
import ItemImage from "@/game-tft/ItemImage.jsx";
import ParsedDescription from "@/game-tft/ParsedDescription.jsx";
import DraggableUnit from "@/shared/DraggableUnit.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";

export default function InGameItemsOverlay({
  items,
  itemsLocalized,
  itemStatsBySet,
  set,
  itemCombinationsOverlay,
  wndAttributes,
  captureData,
}) {
  const refDraggable = useRef(null);
  const { scaleFactor } = wndAttributes;
  const [currItem, setCurrItem] = useState("");
  const isCurrItem = Boolean(currItem);
  const [completedItem, setCompletedItem] = useState(null);
  const isCompletedItem = Boolean(completedItem);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itemsBySet = items ?? {};
  const itemPossibilities = useMemo(() => {
    const result = [];
    if (!currItem) return result;
    const set = new Set();
    for (const basicItem of BASIC_ITEMS) {
      for (const key in itemsBySet) {
        const itemBySet = itemsBySet[key];
        const buildsFrom = itemBySet.buildsFrom;
        if (
          !Array.isArray(buildsFrom) ||
          !buildsFrom.length ||
          set.has(itemBySet.apiKey)
        )
          continue;
        const isConfig =
          currItem === buildsFrom[0] && basicItem === buildsFrom[1];
        const isConfig2 =
          currItem === buildsFrom[1] && basicItem === buildsFrom[0];
        const isConfigOk = isConfig || isConfig2;
        if (!isConfigOk) continue;
        result.push(itemBySet);
        set.add(itemBySet.apiKey);
      }
    }
    set.clear();
    return result;
  }, [currItem, itemsBySet]);
  function onMouseLeave() {
    setCompletedItem(null);
  }
  function setCurrentItem(next) {
    return () =>
      setCurrItem((prev) => {
        captureData({
          name: "items",
          action: "click",
          target: next,
        });
        if (next === prev) return "";
        return next;
      });
  }

  // tracking
  useEffect(() => {
    captureData({
      name: "items",
      action: "view",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { position } = itemCombinationsOverlay ?? {};
  const undraggedPosition =
    position && (position.x !== 0 || position.y !== 0)
      ? css`
          position: absolute;
          top: ${position.y}px;
          left: ${position.x}px;
        `
      : "absolute top-0 right-0";
  return (
    <DraggableUnit
      draggableAreaRef={refDraggable}
      undraggedPosition={undraggedPosition}
      onDragEnd={(x, y) =>
        setOverlayInGameSetting(["itemCombinationsOverlay", "position"], {
          x,
          y,
        })
      }
    >
      <div
        onMouseLeave={onMouseLeave}
        ref={refDraggable}
        style={{
          transform: `scale(${
            ((itemCombinationsOverlay.size?.value ?? 100) / 100) * scaleFactor
          })`,
          transformOrigin: "top right",
        }}
      >
        <BasicItems>
          {BASIC_ITEMS.map((item) => {
            const isSelected = item === currItem;
            return (
              <ItemImage
                key={item}
                itemKey={item}
                selectedSet={set}
                size="38px"
                onClick={setCurrentItem(item)}
                $opacity={!isSelected && isCurrItem && 0.5}
                $borderColor={isSelected && "var(--shade1)"}
              />
            );
          })}
        </BasicItems>
        {isCurrItem && (
          <CombinedItems>
            <div className="flex justify-around">
              {BASIC_ITEMS.map((item) => (
                <ItemImage
                  key={item}
                  itemKey={item}
                  selectedSet={set}
                  size="18px"
                />
              ))}
            </div>
            <div className="flex gap-1">
              {itemPossibilities.map((item) => {
                const itemTier = itemStatsBySet?.[item?.apiKey]?.tier;
                const TierIcon = itemTier && getTierIcon(itemTier);
                const isSelected = completedItem?.apiKey === item.apiKey;
                return (
                  <ItemContainer
                    key={item.apiKey}
                    onMouseEnter={() => setCompletedItem(item)}
                  >
                    <ItemImage
                      itemKey={item.apiKey}
                      selectedSet={set}
                      size="38px"
                      $borderColor={isSelected && "var(--shade1)"}
                    />
                    {TierIcon ? (
                      <img
                        className="tier-icon"
                        src={TierIcon}
                        width="28"
                        height="28"
                      />
                    ) : null}
                  </ItemContainer>
                );
              })}
            </div>
            {isCompletedItem && (
              <div className="pad-1">
                <div className="type-body1">
                  {itemsLocalized?.[completedItem?.apiKey]?.name ||
                    completedItem.name}
                </div>
                <ParsedDescription
                  className="type-body2 color-shade1"
                  text={
                    itemsLocalized?.[completedItem?.apiKey]?.description ||
                    completedItem.description
                  }
                  iconSize={"var(--sp-4)"}
                />
              </div>
            )}
          </CombinedItems>
        )}
      </div>
    </DraggableUnit>
  );
}

const BasicItems = styled(InGameBox, forwardRef)`
  padding: var(--sp-1);
  display: flex;
  gap: var(--sp-1);
  flex-direction: row;
`;
const CombinedItems = styled(InGameBox, forwardRef)`
  display: flex;
  flex-direction: column;
  padding: 0 var(--sp-1) var(--sp-1) var(--sp-1);
  gap: var(--sp-2);
  width: min-content;
`;
