import React from "react";
import { styled } from "goober";

import StaticTFT from "@/game-tft/static.mjs";

const ImageFrame = styled("div")`
  box-sizing: border-box;
  border-radius: var(--br);
`;
const Image = styled("img")`
  box-sizing: border-box;
  display: block;
  background: var(--shade9);
  border-radius: var(--br);
  border: var(--sp-px) solid var(--shade8);
`;

const ItemImage = ({
  itemKey,
  size = "var(--sp-10_5)",
  selectedSet,
  $borderColor = "var(--shade5)",
  $opacity = 1,
  ...restProps
}) => {
  return (
    <ImageFrame
      style={{
        width: size,
        height: size,
        border: `var(--sp-px) solid ${$borderColor}`,
        opacity: $opacity,
      }}
      {...restProps}
    >
      <Image
        src={StaticTFT.getItemImage(itemKey, selectedSet)}
        width={size}
        height={size}
      />
    </ImageFrame>
  );
};

export default ItemImage;
